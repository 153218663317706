@font-face {
  font-family: 'Karla';
  src: url('Karla-Light.eot');
  src: url('Karla-Light.eot?#iefix') format('embedded-opentype'),
    url('Karla-Light.svg#Karla-Light') format('svg'),
    url('Karla-Light.ttf') format('truetype'),
    url('Karla-Light.woff') format('woff'),
    url('Karla-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Karla';
  src: url('Karla-LightItalic.eot');
  src: url('Karla-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('Karla-LightItalic.svg#Karla-LightItalic') format('svg'),
    url('Karla-LightItalic.ttf') format('truetype'),
    url('Karla-LightItalic.woff') format('woff'),
    url('Karla-LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Karla';
  src: url('Karla-Regular.eot');
  src: url('Karla-Regular.eot?#iefix') format('embedded-opentype'),
    url('Karla-Regular.svg#Karla-Regular') format('svg'),
    url('Karla-Regular.ttf') format('truetype'),
    url('Karla-Regular.woff') format('woff'),
    url('Karla-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Karla-Italic';
  src: url('Karla-Italic.eot');
  src: url('Karla-Italic.eot?#iefix') format('embedded-opentype'),
    url('Karla-Italic.svg#Karla-Italic') format('svg'),
    url('Karla-Italic.ttf') format('truetype'),
    url('Karla-Italic.woff') format('woff'),
    url('Karla-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Karla';
  src: url('Karla-SemiBoldItalic.eot');
  src: url('Karla-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('Karla-SemiBoldItalic.svg#Karla-SemiBoldItalic') format('svg'),
    url('Karla-SemiBoldItalic.ttf') format('truetype'),
    url('Karla-SemiBoldItalic.woff') format('woff'),
    url('Karla-SemiBoldItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Karla';
  src: url('Karla-SemiBold.eot');
  src: url('Karla-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('Karla-SemiBold.svg#Karla-SemiBold') format('svg'),
    url('Karla-SemiBold.ttf') format('truetype'),
    url('Karla-SemiBold.woff') format('woff'),
    url('Karla-SemiBold.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Karla';
  src: url('Karla-Medium.eot');
  src: url('Karla-Medium.eot?#iefix') format('embedded-opentype'),
    url('Karla-Medium.svg#Karla-Medium') format('svg'),
    url('Karla-Medium.ttf') format('truetype'),
    url('Karla-Medium.woff') format('woff'),
    url('Karla-Medium.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Karla';
  src: url('Karla-MediumItalic.eot');
  src: url('Karla-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('Karla-MediumItalic.svg#Karla-MediumItalic') format('svg'),
    url('Karla-MediumItalic.ttf') format('truetype'),
    url('Karla-MediumItalic.woff') format('woff'),
    url('Karla-MediumItalic.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Karla';
  src: url('Karla-Bold.eot');
  src: url('Karla-Bold.eot?#iefix') format('embedded-opentype'),
    url('Karla-Bold.svg#Karla-Bold') format('svg'),
    url('Karla-Bold.ttf') format('truetype'),
    url('Karla-Bold.woff') format('woff'),
    url('Karla-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Karla';
  src: url('Karla-BoldItalic.eot');
  src: url('Karla-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('Karla-BoldItalic.svg#Karla-BoldItalic') format('svg'),
    url('Karla-BoldItalic.ttf') format('truetype'),
    url('Karla-BoldItalic.woff') format('woff'),
    url('Karla-BoldItalic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
}
